.messageBox {
  padding-top: 35px;
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
  &__head {
    margin-bottom: 35px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      margin-bottom: 40px;
    }
  }
  &__title {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 3px;
  }
  &__text {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }
  &_items {
    display: grid;
    gap: 34px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1240px;
    margin: 0 auto;
    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      gap: 30px 24px;
    }
  }
}

.msgBlock {
  border-radius: 12.588px;
  border: 1px solid #fff;
  padding: 22px;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 18px;
  }
  &__head {
    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px;
    box-sizing: border-box;
    @media only screen and (max-width: 767px) {
      height: 160px;
    }
    button {
      background: transparent;
      border: none;
      box-shadow: none;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 0;
      overflow: hidden;
      position: absolute;
      top: -20px;
      @media only screen and (max-width: 767px) {
        height: 28px;
        width: 28px;
        top: -14px;
        svg {
          height: 28px;
          width: 28px;
        }
      }
    }
  }
  &__title {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  &__text {
    color: #fff;
    text-align: center;
    font-family: Merriweather;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  &__footer {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
}
