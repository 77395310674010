.emptyBlock {
  margin: 25px auto 0;
  max-width: 1240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    margin: 35px auto 0;
    justify-content: center;
  }
  &__img {
    text-align: center;
    max-width: 422px;
    @media only screen and (max-width: 991px) {
      max-width: 322px;
    }
    svg {
      width: 100%;
      height: 200px;
    }
    h5 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 30px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 20px;
    }
  }
  &__text {
    @media only screen and (max-width: 767px) {
      display: none;
    }
    p {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 30px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      position: relative;
      display: flex;
      align-items: center;
      gap: 28px;
      margin-top: 85px;
      @media only screen and (max-width: 1200px) {
        gap: 15px;
      }
      &::after {
        content: "";
        height: 1px;
        width: 88px;
        background: #fff;
        display: block;
        @media only screen and (max-width: 1200px) {
          width: 80px;
        }
      }
    }
  }
  & > div:last-child {
    p {
      margin-bottom: 85px;
      margin-top: 0;
      &:after {
        display: none;
      }
      &::before {
        content: "";
        height: 1px;
        width: 88px;
        background: #fff;
        display: block;
      }
    }
  }
}
