.btn {
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fff;
  display: inline-flex;
  padding: 16px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
