@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  font-family: Montserrat;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.ceo-text {
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a,
button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

.pageWrapper {
  background: #1d1d1b;
  min-height: 100vh;
  text-align: center;
  &__head {
    padding: 25px 10px 80px;
    @media only screen and (max-width: 767px) {
      padding: 15px 10px 20px;
    }
    &-text {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }
  &__body {
    padding: 0 10px 10px;
  }
  &__title {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    @media only screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
}
