.selectorBlock {
  &__title {
    color: #fff;
    text-align: center;
    font-family: Merriweather;
    font-size: 42px;
    font-style: italic;
    font-weight: 400;
    line-height: 46px;

    /* 107.017% */
    @media only screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
      /* 120% */
    }
  }

  &__sizeButtons {
    display: grid;
    grid-template-columns: 280px 280px;
    justify-content: center;
    gap: 12px;
    margin-top: 28px;

    @media only screen and (max-width: 767px) {
      margin-top: 20px;
      grid-template-columns: 1fr;
    }

    button {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      padding: 10px 24px;
      align-items: center;
      gap: 20px;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      transition: all 0.2s ease;

      @media only screen and (max-width: 767px) {
        padding: 6px 24px;
        font-size: 20px;
      }

      &:hover {
        background: #fff;
      }

      &.active {
        background: #fff;
      }
    }
  }

  &__average {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    margin-top: 36px;
    min-height: 126px;

    @media only screen and (max-width: 767px) {
      display: grid;
      gap: 10px;
      justify-content: stretch;
      margin-top: 10px
    }

    &-title {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media only screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      gap: 12px;

      @media only screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
      }

      button {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 20px;
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        transition: all 0.2s ease;

        @media only screen and (max-width: 767px) {
          padding: 6px 24px;
          font-size: 20px;
        }

        &:hover {
          background: #fff;
        }

        &.active {
          background: #fff;
        }
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 36px;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr;
    }

    button {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      padding: 10px 24px;
      align-items: center;
      gap: 20px;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      transition: all 0.2s ease;

      @media only screen and (max-width: 767px) {
        padding: 6px 24px;
        font-size: 20px;
      }

      &:hover {
        background: #fff;
      }

      &.active {
        background: #fff;
      }
    }
  }
}

.stepper {
  padding: 12px 0;

  &__footer {
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding-top: 16px;

      img {
        height: 290px;
        width: 290px;
        object-fit: cover;
      }
    }
  }

  &__nav {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 16px;
    grid-template-columns: 128px 60px 128px;

    @media only screen and (max-width: 767px) {
      display: flex;
    }

    &-text {
      padding: 10px 12px;
      border-radius: 6px;
      background: #fff;
      height: 40px;
      box-sizing: border-box;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }

    button {
      height: 40px;
      box-sizing: border-box;
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 10px 12px;
      border-radius: 6px;
      background: #fff;
      width: fit-content;
      margin-left: auto;
    }

    &> :last-child {
      display: flex;
      justify-content: flex-start;
      text-decoration: none;
      margin-left: 0;
    }

    a {
      display: flex;
      justify-content: flex-start;
      text-decoration: none;

      button {
        margin-left: 0;
      }
    }
  }
}