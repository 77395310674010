.contentBlock {
  padding: 22px 0;

  &__title {
    color: #fff;
    text-align: center;
    font-family: Merriweather;
    font-size: 66px;
    font-style: italic;
    font-weight: 400;
    line-height: 72.102px;
    /* 109.246% */
    margin: 0;

    &--small {
      font-size: 56px;
      font-style: italic;
      font-weight: 400;
      line-height: 62px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 40px;
      line-height: 46px;
    }

    &--extraSmall {
      font-size: 32px;
      line-height: 40px;

      @media only screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 38px;
      }
    }
  }

  &__text {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    /* 200% */
    max-width: 550px;
    margin: 12px auto 0;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__smallText {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    max-width: 550px;
    margin: 24px auto 0;
    /* 28.8px */
  }

  &__btn {
    margin-top: 43px;
    text-align: center;

    button {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__form {
    max-width: 685px;
    margin: 30px auto 0;

    &-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      overflow: hidden;
      background: #3f3f3f;
      border-radius: 10px;
      border: 1px solid #fff;

      @media only screen and (max-width: 767px) {
        border: none;
        background: transparent;
        display: grid;
        gap: 10px;
        height: auto;
        border-radius: 0;
        width: 100%;
        justify-content: stretch;
      }

      input {
        width: 100%;
        padding: 16px 28px;
        border: none;
        background: transparent;
        text-align: center;
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;

        @media only screen and (max-width: 767px) {
          background: #3f3f3f;
          width: 100%;
          border-radius: 10px;
          height: 52px;
          box-sizing: border-box;
        }
      }

      button {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 194px;
        padding: 14px 20px;
        border-radius: 0;

        @media only screen and (max-width: 767px) {
          width: 100%;
          border-radius: 10px;
          height: 52px;
          box-sizing: border-box;
        }
      }
    }
  }
}